import React from "react"
import "../styles/global.css"
import Layout from "../components/Layout/Layout"
import {makeStyles} from "@material-ui/styles"
import ProjectGrid from "../components/Projects/ProjectGrid/ProjectGrid";

const useStyles = makeStyles({
    root: {
        flexGrow: "1",
    },
});

export default function Projects() {
    const classes = useStyles();
    return (
        <Layout>
            <ProjectGrid/>
        </Layout>
    )
}

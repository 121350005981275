import React from "react"
import Grid from "@material-ui/core/Grid";
import {Container} from "@material-ui/core";

export default function () {
    return (
        <Grid container justify="center">
            <Grid item xs={3} style={{background: "red", height: "200px", width: "200px"}}>

            </Grid>
            <Grid item xs={3} style={{background: "green", height: "200px", width: "200px"}}>

            </Grid>
            <Grid item xs={3} style={{background: "purple", height: "200px", width: "200px"}}>

            </Grid>
        </Grid>
    )
}